import { RBackground, RBackgroundConfig } from "@/component/RBackground";
import { autoAPIBaseUrlString } from "@/config/config";
import { getOverflowStyles } from "@/function/CompatibilityFix";
import { Button, Checkbox, Form, Input, Modal, Select, Typography } from "antd";
import { FormProps } from "antd/es/form/Form";
import React, { PureComponent } from "react";
import "./index.less";
const { Option } = Select;
const { Title } = Typography;

const PROJECT_ID = 1

type RButtonConfig = {
    debug?: boolean
}

export const RButtonConfig = React.createContext<RButtonConfig>({});

type CheckProps = {
    show: boolean
    onClose: () => void
}


type CheckState = {
    showSuccessModal?: boolean
    currentSelectedLocationStringKey?: string
    loading?: boolean

    locations?: {
        id: number;
        name: string;
    }[]
    times?: {
        id: number;
        activityId: number;//locations
        startTime: string | Date;
        endTime: string | Date;
        regEndTime: string | Date;
    }[]
    loadedTime?: {
        id: number;
    }[]
    seats?: {
        id: number;
        scheduleId: number;
        orderable: boolean;
    }[]

    selectedActivity?: string
    selectableSchedules?: {
        id: number;
        activityId: number;//locations
        startTime: string | Date;
        endTime: string | Date;
        regEndTime: string | Date;
        hasOrderableSeat?: boolean;
        seats?: {
            id: number;
            scheduleId: number;
            orderable: boolean;
        }[]
    }[]

}
export class Check extends PureComponent<CheckProps, CheckState> {
    timer?: NodeJS.Timer;
    system: BookingSystem;
    constructor(props: CheckProps) {
        super(props);
        this.state = {
        };
        this.system = new BookingSystem({
            serverURL: new URL(autoAPIBaseUrlString())
        })
    }
    render() {
        const preregistration = false
        return <>
            <Modal
                width={"80%"}
                style={{
                    maxWidth: 925,
                    borderRadius: 25,
                    overflow: "hidden"
                }}
                closable
                // visible={this.state.open}
                // open={this.state.open}
                open={this.props.show}
                title={null}
                footer={null}
                // onOk={handleOk}
                onCancel={this.props.onClose}
            >
                {this.renderSuccess()}

                <RBackgroundConfig.Provider value={{ debug: false }}>
                    <RBackground anchorId={"video"} url={"/background2/banner_v2.jpg"} />
                </RBackgroundConfig.Provider>
                <div>

                    <Title
                        style={{
                            textAlign: "center",
                            fontSize: 18,
                            fontWeight: 600,
                            color: "rgb(0 0 0 / 80%)",
                        }}
                    >
                        {"請填寫以下表格"}
                        <br />
                        {"揀選測試地點、日期及時間："}
                    </Title>

                </div>
                <div style={{ display: "flex", justifyContent: "center", filter: `hue-rotate(${145}deg)`, padding: 24 }}>
                    <div style={{ maxWidth: 765, width: "100%" }}>
                        <Typography.Paragraph style={{ color: '#0089ff', display: preregistration ? "block" : "none" }}>
                            {"很抱歉! 由於反應熱烈，第一階段的免費檢測已經滿額。歡迎留下你的聯絡資料，如有下一階段的免費檢測，我們會以手機短訊(SMS)通知閣下有關網上登記事宜，敬請留意。"}
                        </Typography.Paragraph>
                        <Form
                            name="basic"
                            // initialValues={this.state.initialValues}
                            onFinish={(event) => {
                                console.log("onFinish", event)
                                const result = event as {
                                    agree: boolean
                                    check2: boolean
                                    email: string
                                    location: string
                                    phone: string
                                    username: string
                                    time: number
                                }
                                // console.log(event);
                                // this.validate(event);
                                this.sendOrder(result.time || undefined, result.username || "", result.phone || "", result.email || "", result.agree ? '是' : '否')
                            }}
                            onFinishFailed={(event) => {
                                console.log(event);
                            }}
                            onFieldsChange={this.onFormFieldsChange.bind(this)}
                        // onFieldsChange={(event) => {
                        //     const first = event.shift()
                        //     if (first) {
                        //         console.log("first", first.name)

                        //     }
                        //     let item = event[0].name[0];
                        //     let value = event[0].value;
                        //     if (item == "location") {
                        //         console.debug("onFieldsChange", value);
                        //         let currentLocation = value;
                        //         for (let item of this.state.regionNames || []) {
                        //             if (item.id == value) {
                        //                 currentLocation = item.name;
                        //             }
                        //         }
                        //         Fthis.setState({
                        //             currentLocation,
                        //             initialValues: {
                        //                 time: "",
                        //             },
                        //         });
                        //     }
                        // }}
                        // style={{  filter: `hue-rotate(${145}deg)`  }}
                        >
                            <Form.Item
                                name="username"
                                rules={[
                                    // { required: debug ? false : true, message: '必填' },
                                    { required: true, message: "必填" },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //         try {
                                    //             if (value.trim().length > 0) {
                                    //                 return Promise.resolve();
                                    //             }
                                    //         } catch (error) { }
                                    //         return Promise.reject(new Error("必填"));
                                    //     },
                                    // }),
                                ]}
                            >
                                <Input placeholder={"姓名"} />
                            </Form.Item>

                            <Form.Item name="phone" rules={[{ required: true, message: "必填" }]}>
                                <Input placeholder={"聯絡電話"} />
                            </Form.Item>
                            <Form.Item name="email" style={{ display: preregistration ? "none" : undefined }}
                                rules={[{ required: preregistration ? false : true, message: "必填" }]}
                            >
                                <Input placeholder={"電郵"} />
                            </Form.Item>

                            <Form.Item
                                label="選擇測試中心地區"
                                name="location"
                                dependencies={["time"]}
                                style={{ display: preregistration ? "none" : undefined }}
                            rules={[{ required: preregistration ? false : true, message: "必填" }]}
                            >
                                <Select
                                    disabled={typeof this.state.locations === "undefined"}
                                    onDropdownVisibleChange={this.onDropdownVisibleChange}
                                    loading={typeof this.state.locations === "undefined"}
                                    onChange={this.onActivityChanged.bind(this)}>
                                    {Array.from(this.state.locations || []).map((item) => {
                                        return <Option value={`${item.id}`}>{item.name}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                shouldUpdate
                                label="選擇測試日期及時間"
                                dependencies={["location"]}
                                style={{ display: preregistration ? "none" : undefined }}
                                name="time"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator: (_, value) => {
                                            if(preregistration){
                                                return Promise.resolve();
                                            }
                                            for (let item of this.state.selectableSchedules || []) {
                                                if (item.id === value) {
                                                    return Promise.resolve();
                                                }
                                            }
                                            return Promise.reject(new Error("請選擇時間"));
                                        },
                                    }),
                                ]}
                            >
                                {(() => {
                                    let loadedActivitysTime = false
                                    Array.from(this.state.times || []).map((item) => {
                                        if (String(item.activityId) === this.state.currentSelectedLocationStringKey) {
                                            loadedActivitysTime = true
                                        }
                                    })
                                    return (
                                        <Select
                                            disabled={!this.state.selectedActivity || (!!this.state.selectedActivity && !this.state.selectableSchedules)}
                                            loading={!!this.state.selectedActivity && !this.state.selectableSchedules}
                                            onDropdownVisibleChange={this.onDropdownVisibleChange}
                                            className={"biki-date-select-2"}
                                        >
                                            {(() => {
                                                if (!this.state.selectableSchedules) { return }
                                                let loadedActivitynTime = false

                                                return Array.from(this.state.selectableSchedules).map((item) => {

                                                    let displays = new Date(item.startTime).toLocaleString()
                                                    {
                                                        let date = new Date(item.startTime).toLocaleDateString(
                                                            "zh-TW",
                                                            { month: "long", day: "numeric" }
                                                        );
                                                        let week = new Date(item.startTime)
                                                            .toLocaleDateString("zh-TW", { weekday: "short" })
                                                            .replace("週", "");
                                                        let timeStart = new Date(item.startTime).toLocaleTimeString(
                                                            "en-US",
                                                            { hour12: true, hour: "numeric", minute: "numeric" }
                                                        );
                                                        let timeEnd = new Date(item.endTime).toLocaleTimeString(
                                                            "en-US",
                                                            { hour12: true, hour: "numeric", minute: "numeric" }
                                                        );
                                                        displays = `${date}(${week}) ${timeStart} - ${timeEnd}`
                                                    }
                                                    loadedActivitynTime = true
                                                    let disabled = false
                                                    let afterfix = ""

                                                    if (new Date(item.regEndTime).valueOf() < new Date().valueOf()) {
                                                        disabled = true
                                                        afterfix = "(已經截止報名)"
                                                    } else if (!("hasOrderableSeat" in item)) {
                                                        disabled = true
                                                        afterfix = "[檢查座位中]"
                                                    } else if (item.seats?.length === 0) {
                                                        //没有座位，忽略
                                                        return
                                                        // } else if (new Date(item.endTime).valueOf() < new Date().valueOf()) {
                                                        //     //活动结束了，忽略
                                                        //     return
                                                    } else if (!item.hasOrderableSeat) {
                                                        disabled = true
                                                        afterfix = "(已滿額)"
                                                    }
                                                    if (false) {
                                                        return <Option
                                                            disabled={disabled}
                                                            value={item.id}>
                                                            ({item.id})
                                                            (endTime{new Date(item.regEndTime).toLocaleString()})
                                                            ({new Date(item.startTime).toLocaleString()})
                                                            {afterfix}
                                                            {/* {`${date}(${week}) ${timeStart} - ${timeEnd}`} */}
                                                        </Option>
                                                    } else {
                                                        return <Select.Option
                                                            disabled={disabled}
                                                            value={item.id}>
                                                            {displays}
                                                            {afterfix}
                                                        </Select.Option>
                                                    }



                                                })
                                            })()}
                                        </Select>
                                    );
                                })()}
                            </Form.Item>

                            <div style={{ textAlign: "center", marginBottom: 24 }}>
                                {"注意："}
                                <br />
                                {"已選擇的測試中心地區、日期及時間，有可能因應實際情況而更改，「康心摯友」專員會盡快與您聯絡，以確認您的登記。"}
                            </div>

                            <Form.Item
                                name="agree"
                                valuePropName="checked"
                                rules={[
                                    {
                                        required: false,
                                        message: "必須勾選",
                                    },
                                ]}
                            >
                                <Checkbox
                                >
                                    {
                                        "本人願意接收有關阿斯利康健康講座/活動、藥物產品、會員優惠、健康醫學及來自阿斯利康關聯保健/醫藥機構的資料，並同意可為該目的使用本人個人資料。"
                                    }
                                </Checkbox>
                            </Form.Item>
                            <Form.Item
                                name="check2"
                                valuePropName="checked"
                                rules={[{ required: true, message: "必須勾選", },]}
                            >
                                <Checkbox>
                                    {"本人已閱讀及同意「康心摯友」有關 "}
                                    <a style={{ textDecoration: "underline" }} onClick={() => window.open("https://azhealthclub.com.hk/index/fb_privacy_statement")}>
                                        {"私隱政策聲明"}
                                    </a>
                                    {" 及 "}
                                    <a style={{ textDecoration: "underline" }} onClick={() => window.open("https://azhealthclub.com.hk/index/forxigadisclaimer")}>
                                        {"免責聲明"}
                                    </a>
                                    {" 之條款。"}
                                </Checkbox>
                            </Form.Item>

                            <div style={{ textAlign: "center" }}>
                                {"如有任何疑問，請致電「康心摯友」熱線 : 2941 5388查詢 ( 星期一至五  9:00 am  to 5:00 pm ) 謝謝！"}
                            </div>
                            <Form.Item>
                                <div style={{ width: "100%", textAlign: "center", marginTop: 24 }} >
                                    <Button
                                        size="large"
                                        type="primary"
                                        style={{ width: 250 }}
                                        loading={this.state.loading}
                                        htmlType="submit">
                                        {"提交"}
                                    </Button>
                                </div>
                            </Form.Item>

                        </Form>
                    </div>
                </div>
            </Modal></>
    }
    renderSuccess() {
        return <Modal
            className={"bookingResultModal"}
            visible={this.state.showSuccessModal}
            closable={false}
            title={null}
            footer={null}>
            <div style={{ textAlign: "center", padding: 42 }}>
                <Title level={5}>{"感謝您的登記！"}</Title>
                <Title level={5}>
                    {"「康心摰友」專員會盡快與您聯絡，確認測試的詳細地點、日期及時間。請耐心等候!"}
                    <br />
                    {"已選擇的測試中心地點、日期及時間，有可能因應實際情況而更改。"}
                </Title>
                <Title level={5} style={{ textTransform: "lowercase" }}>
                    {"如有任何疑問，請致電「康心摯友」熱線 : 2941 5388查詢( 星期一至五  9:00 am  to 5:00 pm ) 謝謝！"}
                </Title>
                <Title level={5} style={{ display: "none" }}>
                    {""}
                </Title>
                <div style={{ height: 20 }}></div>
                <Button type="primary" style={{ filter: "hue-rotate(135deg)" }} onClick={this.closeModal.bind(this)}>
                    {"返回網站"}
                </Button>
            </div>
        </Modal>
    }
    async componentDidMount() {
        const locations = await this.system.getActivity()
        this.setState({ locations })
 
    }
    private onFormFieldsChange = (event: Parameters<Extract<FormProps["onFieldsChange"], Function>>[0]) => {
        const current = event.shift()
        if (!current) { return }
        const name = current.name.toString()
        const value = current.value
        console.log("onFormFieldsChange", { name, value })
        switch (name) {
            case "location":
                this.setState({ currentSelectedLocationStringKey: value })
                break;

            default:
                break;
        }
    }
    private async onActivityChanged(activityStringId: string) {
        this.setState({ selectableSchedules: undefined })

        this.setState({ selectedActivity: activityStringId })
        const data = await this.system.getSchedule(Number(activityStringId))
        console.log("selectableSchedules", data)
        data.sort((a, b) => new Date(a.startTime).valueOf() - new Date(b.startTime).valueOf())

        this.setState({ selectableSchedules: data })

        const selectableSchedules = []

        for (let schedule of data) {
            const thisSeats = await this.system.getSeat(schedule.id)
            // console.log("thisSeats", thisSeats)
            let hasOrderableSeat = false

            for (let seat of thisSeats) {
                if (seat.orderable) {
                    hasOrderableSeat = true
                }
            }
            const nextSelectableSchedules = Array.from([...this.state.selectableSchedules || []], item => {
                if (item.id !== schedule.id) {
                    return item
                } else {
                    return {
                        ...schedule,
                        seats: thisSeats,
                        hasOrderableSeat,
                    }
                }
            })


            // selectableSchedules.push({
            //     ...schedule,
            //     seats: thisSeats,
            //     hasOrderableSeat,
            // })
            this.setState({ selectableSchedules: nextSelectableSchedules })
        }
        // this.setState({ selectableSchedules })
    }

    getSeats(scheduleId: number) {
        let loadedTimenSeat = false
        if (typeof this.state.seats === "undefined") {
            return {
                total: 0,
                left: 0
            }
        }
        let totalCount = 0
        let leftCount = 0
        for (let seat of this.state.seats) {
            if (seat.scheduleId === scheduleId) {
                loadedTimenSeat = true
                totalCount = totalCount + 1
                if (seat.orderable) {
                    leftCount = leftCount + 1
                }
            }
        }
        return {
            loaded: loadedTimenSeat,
            total: totalCount,
            left: leftCount
        }
    }
    private onDropdownVisibleChange = function (state: boolean) {
        let elements = document.getElementsByClassName("ant-modal-wrap");
        for (let key in elements) {
            if (String(Number(key)) === key) {
                const element = elements[key]
                if (element instanceof HTMLDivElement) {
                    // let overflow = "overlay";
                    let overflow = getOverflowStyles("overlay")
                    if (state == true) {
                        overflow = "hidden";
                    }
                    element.style.overflow = overflow;
                }
            }
        }

    };
    async sendOrder(scheduleId: number | undefined, name: string, phoneNumber: string, email: string, agree: string) {
        this.setState({ loading: true })
        setTimeout(async () => {
            await this.system.order(PROJECT_ID, scheduleId, name, phoneNumber, email, agree)
            this.setState({ showSuccessModal: true })
            this.setState({ loading: false })
            // setTimeout(() => {
            //     this.props.onClose()
            // }, 600);
        }, 600);
    }
    closeModal() {
        this.setState({
            loading: false,
            showSuccessModal: false
        })
        this.forceUpdate()

        setTimeout(() => {
            this.props.onClose()
        }, 600);
    }
}



type BookingSystemConfig = {
    serverURL: URL
}
export class BookingSystem {
    private serverURL: URL
    constructor(props: BookingSystemConfig) {
        this.serverURL = props.serverURL
    }
    private async request(pathname: string, data?: {}) {
        let urlString = this.serverURL.href
        const lastUrlStringText = urlString.substring(urlString.length - 1)
        if (lastUrlStringText === "/") {
            urlString = urlString.substring(0, urlString.length - 1)
        }
        const url = new URL(urlString + pathname)

        try {

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    ticket: "hello",
                    ...data,
                })
            })
            const json = await response.json()
            if (!json.success) {
                const message = json.message || "未知错误"
                // switch (key) {
                //     case value:

                //         break;

                //     default:
                //         break;
                // }
                throw message
            }
            return json.data
        } catch (error) {
            // let message = "BookingSystem.request.NetworkError " + url.href
            // let message = "網絡請求失敗 BookingSystem.request.NetworkError " + url.href
            let message = "網絡請求失敗"
            if (typeof (error) === "string") {
                message = error
            }
            // message.error(message);
            // setTimeout(() => {
            console.log("message", message)
            Modal.error({
                title: "操作失敗",
                content: message,
            });
            // }, 2500);
            throw (message)
        }
    }
    async getActivity() {
        return (await this.request("/activity/pubList", {
            projectId: PROJECT_ID
        })).data as { id: number; name: string; }[]
    }
    async getSchedule(activityId: number) {
        return (await this.request("/schedule/pubList", {
            activityId: activityId
        })).data as {
            id: number
            activityId: number
            startTime: string | Date
            endTime: string | Date
            regEndTime: string | Date
        }[]
    }
    async getSeat(scheduleId: number) {
        return (await this.request("/seat/pubList", {
            scheduleId: scheduleId
        })).data as {
            id: number;
            scheduleId: number;
            orderable: boolean;
        }[]
    }
    async order(projectId: number, scheduleId: number | undefined, name: string, phoneNumber: string, email: string, agree: string) {
      console.log('请求参数---projectId, scheduleId, name, phoneNumber, email, agree', projectId, scheduleId, name, phoneNumber, email, agree);
        return (await this.request("/order/pubAdd", {
            projectId,
            scheduleId,
            name,
            phoneNumber,
            email,
            agree,
            channel:`High_cholesterol`,
        })).data as {}
    }

}
