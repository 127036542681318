
export const baseUrl = new URL("http://192.168.100.230:3005")


export const autoResBaseUrlString = () => {
    let target = "undef"
    switch (document.location.hostname) {
        case "azhealthclub.com.hk":
            target = `https://azhealthclub.com.hk/modules/custom/azhealthclub_modify/templates/high_cholesterol/dist`
            break;
        case "az.squarebear.io": 
            target = `https://az.squarebear.io/modules/custom/azhealthclub_modify/templates/high_cholesterol/dist`
            break;

        default:
            // target = `https://foxiga.ckd.test.yubis.net`
            // target = `http://localhost:3026/`
            target = `${document.location.protocol}//${document.location.hostname}`
            break;

    }
    //@ts-ignore
    globalThis._debug_autoResBaseUrlString = target
    return target
}
export const autoAPIBaseUrlString = () => {
    let target = "undef"
    switch (document.location.hostname) {
        case "azhealthclub.com.hk":
            target = `https://azhealthclub.com.hk/api/ckd`
            break;
        case "az.squarebear.io":
            target = `https://foxiga.ckd.test.server.yubis.net`
            break;
        default:
            if (document.location.hostname.endsWith("pages.dev")) {
                target = `https://foxiga.ckd.test.server.yubis.net`
            } else {
                // target = `http://127.0.0.1:18092`
                target = `http://18.167.151.93:18092`
            }
            break;
    }
    //@ts-ignore
    globalThis._debug_autoAPIBaseUrlString = target
    return target
}
