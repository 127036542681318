import { getImageInfo, getOptimization, ResFetch2URL, resURLConcatenation } from "@/function/Network";
import React, { PureComponent } from "react";


type RBackgroundConfig = { debug?: boolean }
export const RBackgroundConfig = React.createContext<RBackgroundConfig>({});


export class RBackgroundMobile extends PureComponent<RBackgroundProps> {
    constructor(props: RBackgroundProps) {
        super(props);
    }
    render() {
        const width = window.innerWidth
        if (!(width < 900)) {
            return ""
        }
        return <RBackground {...this.props} />
    }


}

export class RBackgroundPC extends PureComponent<RBackgroundProps> {
    constructor(props: RBackgroundProps) {
        super(props);
    }
    render() {
        const width = window.innerWidth
        if ((width < 900)) {
            return ""
        }
        return <RBackground {...this.props} />
    }


}




















type RBackgroundProps = {
    children?: React.ReactNode
    style?: React.CSSProperties
    /**不指定时使用图像尺寸 */
    drawBoardWidth?: number
    // anchorId?: string
    url: string
    anchorId: string
    bgColor?: string
}


type RBackgroundState = {
    backgroundHeight: number
    drawerScale: number
    imageScale: number
}
export class RBackground extends PureComponent<RBackgroundProps, RBackgroundState> {
    static contextType = RBackgroundConfig
    layerRef: React.RefObject<HTMLDivElement>;
    URL?: URL;
    imageInfo?: { height: number, width: number }
    calcSizeProps: { height: number, width: number }
    public static Mobile: typeof RBackgroundMobile = RBackgroundMobile;
    public static PC: typeof RBackgroundPC = RBackgroundPC;

    constructor(props: RBackgroundProps) {
        super(props);
        this.layerRef = React.createRef();
        this.calcSizeProps = { height: 0, width: 0 }
        this.state = {
            backgroundHeight: 0,
            drawerScale: 1,
            imageScale: 1,
        };
    }
    render() {
        return <RBackgroundConfig.Consumer>
            {CONFIG => {
                const debug = !!CONFIG.debug


                return <div
                    className="RBackground"
                    id={this.props.anchorId || "undef"}
                    style={{
                        width: "100%",
                        backgroundColor: this.props.bgColor,
                        height: this.state.backgroundHeight,
                        overflow: "hidden"
                    }}>
                    <div
                        ref={this.layerRef}
                        className={"RBackground-BackgroundLayer"}
                        style={{
                            width: "100%",
                            height: "100%",
                            backgroundColor: debug ? "red" : undefined,
                            backgroundImage: `url(${this.URL?.href})`,
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            opacity: 1,
                            ...this.props.style,
                        }}>
                        <div
                            className={"RBackground-Content"}
                            style={{ transform: `scale(${this.state.drawerScale})`, transformOrigin: "left top" }}>
                            {this.props.children}
                        </div>
                    </div>
                </div >
            }}
        </RBackgroundConfig.Consumer>
    }
    async componentDidMount() {
        const CONTEXT = this.context as RBackgroundConfig
        if (CONTEXT.debug) { console.log("RBackground.debug", this.props.url) }

        this.URL = await resURLConcatenation(this.props.url, true)
        if (CONTEXT.debug) { console.log("RBackground.debug", "resURLConcatenation", this.URL) }

        this.optimize()
        const blobs = await ResFetch2URL(this.URL)
        this.imageInfo = await getImageInfo(blobs.blobUrl)
        this.reCalc()
        window.addEventListener("resize", this.reCalc.bind(this), false);
    }
    async optimize() {
        const data = await getOptimization()
return
        for (let image of data.image) {
            if (image.rPath === this.props.url) {
                if (!this.imageInfo) {
                    this.imageInfo = {
                        width: image.width,
                        height: image.height,
                    }
                    this.reCalc()
                }
            }
        }
    }
    reCalc() {

        if (!this.layerRef || !this.layerRef.current) { return "dom 未就绪" }
        if (!this.imageInfo) { return "image 未就绪" }
        const dom = this.layerRef.current
        const currentHeight = dom.offsetHeight
        const currentWidth = dom.offsetWidth
        const imageHeight = this.imageInfo.height
        const imageWidth = this.imageInfo.width
        const drawerWidth = this.props.drawBoardWidth || imageWidth

        if (this.calcSizeProps.height === currentHeight && this.calcSizeProps.width === currentWidth) { return "无需更新" }
        this.calcSizeProps = { height: currentHeight, width: currentWidth }
        const imageScale = (currentWidth / imageWidth)
        const backgroundHeight = imageHeight * imageScale
        this.setState({ backgroundHeight, imageScale })

        const drawerScale = imageWidth / drawerWidth * imageScale
        this.setState({ drawerScale })

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.reCalc);
    }



}

