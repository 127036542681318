import React from "react";
import { MixLoader } from "react-entrance";
import ReactDOM from "react-dom";
// import {client} from "react-dom";
import Page from "./pages/index"
import { ReactWarn2Warn } from 'fuck-react-warning';



ReactWarn2Warn()


{
    console.debug("初始化React")
    //@ts-ignore
    if (typeof highCholesterolReactButton === "undefined") {
        const button = document.createElement("div")
        button.style.width = "100%"
        button.style.minWidth = "100px"
        button.style.textAlign = "center"
        button.innerHTML = `<div style="max-width: 1200px;min-height: 70px;display: inline-block;width: 100%;text-align: center;position: relative;top: -20px;">
    <div id="highCholesterolReactButtonInner" style="height: 64px;width: 250px;display: inline-flex;border-radius: 38px;background: #FFAC00;border: 4px #FD3970 solid;justify-content: center;align-items: center;font-size: 26px;color: white;font-weight: 600;box-shadow: 0px 0px 0px 5px #ffffff;cursor: pointer;">免費報名詳細檢測</div>
</div>`
        button.id = "highCholesterolReactButton"

        const title = document.getElementsByClassName("views-field-field-image")[0]
        if (title && title.parentElement) {
            title.parentElement.appendChild(button)
            const reactRoot = document.createElement("div")
            reactRoot.id = "highCholesterolReactRoot"
            title.parentElement.appendChild(reactRoot)
        } else {
            console.error("views-field-field-image 未找到！")
        }


    }


}

new MixLoader({
    rootId: "highCholesterolReactRoot",
    rootNode: <Page />
})
